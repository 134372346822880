<template>
    <div class="content_body backfff pad_15" v-loading="loading">
        <el-row class="nav">
            <el-col>
                <el-radio-group size="small" v-model="radio1">
                    <el-radio-button label="基本信息"></el-radio-button>
                    <el-radio-button label="销售范围"></el-radio-button>
                </el-radio-group>
            </el-col>
            <el-col class="fan">
                销售范围
            </el-col>
        </el-row>
        <el-row>
            <el-col class="table-col">
                <div class="marbm_10 martp_10">
                    <el-table size="small"
                              :data="tableData"
                              style="width: 100%"
                              ref="multipleTable"
                              @selection-change="handleSelectionChange"
                    >
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column label="组织单位" prop="id"></el-table-column>
                        <el-table-column label="销售价" prop="name" align="center">
                            <template>
                                <el-input v-model="input2" size="small" style="width:80%">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="最低折扣" prop="name" align="center">
                            <template>
                                <el-input v-model="input2" size="small" style="width:80%">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="最低金额" prop="name" align="center">
                            <template>
                                <el-input v-model="input2" size="small" style="width:80%">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="销售" prop="desc" align="center">
                            <el-table-column label="比例" align="center">
                                <template>
                                    <el-input v-model="input2" size="small" style="width:80%">
                                        <template slot="append">%</template>
                                    </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="固定金额" align="center">
                                <template>
                                    <el-input v-model="input2" size="small" style="width:80%">
                                        <template slot="append">%</template>
                                    </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="提成设置" align="center">
                                <template slot-scope="scope">
                  <span style="color:rgb(72, 97, 252);cursor:pointer;" @click="toogleExpand(scope.row)">职务提成 <i
                          class="el-icon-arrow-down"></i
                  ></span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column type="expand" width="1">
                            <template>
                                <el-form label-position="left" inline class="demo-table-expand">
                                    <div style="display:flex">
                                        <div style="width:60%;color:transparent">
                                            1
                                        </div>
                                        <div style="width:15%;text-align:center">
                                            <el-input v-model="input2" size="small" style="width:69%">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </div>
                                        <div style="width:15%;text-align:center">
                                            <el-input v-model="input2" size="small" style="width:69%">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </div>
                                        <div style="width:10%;text-align:right">
                                            <span style="color:#666666">高级美容师</span>
                                        </div>
                                    </div>
                                </el-form>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <el-row class="footer">
            <el-col :span="14" class="col">
                <el-button class="canel" size="small" v-prevent-click>取消</el-button>
                <el-button class="save" size="small" v-prevent-click>保存</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                treeList: [
                    {
                        id: 1,
                        label: "一级 1",
                        children: [
                            {
                                id: 4,
                                label: "二级 1-1",
                                children: [
                                    {
                                        id: 9,
                                        label: "三级 1-1-1"
                                    },
                                    {
                                        id: 10,
                                        label: "三级 1-1-2"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 2,
                        label: "一级 2",
                        children: [
                            {
                                id: 5,
                                label: "二级 2-1"
                            },
                            {
                                id: 6,
                                label: "二级 2-2"
                            }
                        ]
                    },
                    {
                        id: 3,
                        label: "一级 3",
                        children: [
                            {
                                id: 7,
                                label: "二级 3-1"
                            },
                            {
                                id: 8,
                                label: "二级 3-2"
                            }
                        ]
                    }
                ],
                input2: "",
                loading: false,
                dialogVisible: false,
                dutyList: [],
                tableData: [
                    {
                        id: "杭州丝域企业管理咨询有限公司",
                        name: "",
                        category: "江浙小吃、小吃零食",
                        desc: "荷兰优质淡奶，奶香浓而不腻",
                        address: "上海市普陀区真北路",
                        shop: "王小虎夫妻店",
                        shopId: "10333"
                    }
                ],
                value: "",
                name: "",
                checkList: [],
                ruleForm: {
                    name: "",
                    status: true
                },
                rules: {
                    name: [{required: true, message: "请输入支付名称", trigger: "blur"}],
                    status: [{required: true, message: "请选择有效性", trigger: "change"}]
                },
                //需要给分页组件传的信息
                paginations: {
                    page: 1, // 当前位于哪页
                    total: 5, // 总数
                    page_size: 10, // 1页显示多少条
                    layout: "total,sizes, prev, pager, next,jumper" // 翻页属性
                },
                radio1: "基本信息"
            };
        },
        methods: {
            // 数据显示
            handleSearch: function () {
                let that = this;
                that.paginations.page = 1;
                that.search();
            },
            handleSelectionChange() {
            },
            // 数据显示
            search: function () {
                let that = this;
                that.loading = true;
            },
            toogleExpand(row) {
                let $table = this.$refs.multipleTable;
                $table.toggleRowExpansion(row);
            },
            append() {
            },
            // 上下分页
            handleCurrentChange(page) {
                var that = this;
                that.paginations.page = page;
                that.search();
            },
            //每页条数
            handleSizeChange(val) {
                var that = this;
                that.paginations.page_size = val;
                that.search();
            },
            // 新增
            showAddDialog: function () {
                var that = this;
                that.dialogVisible = true;
            },
            // 跳转
            showEditDialog: function () {
                var that = this;
                that.dialogVisible = true;
            }
        }
    };
</script>

<style scoped lang="scss">
    .content_body {
        .nav {
            .fan {
                line-height: 30px;
                border-bottom: 1px solid #ccc;
                margin-top: 10px;
            }
        }

        .table-col {
            border-bottom: 2px solid #4861fc;
        }

        .left {
        }

        .tree {
            .fenlei {
                padding-left: 15px;
            }
        }

        .footer {
            .col {
                text-align: right;
                margin-top: 20px;

                .canel {
                    border: 1px solid #4861fc;
                    color: #4861fc;
                }

                .save {
                    border: 1px solid #4861fc;
                    color: #ffffff;
                    background: #4861fc;
                }
            }
        }
    }
</style>
